.App {
  text-align: center;
}

body {
  background-color: #0B1215;
  /* keeps scrollbar visible when opening nav menu */
  overflow: visible !important;
  /* keeps body aligned when opening nav menu */
  padding-right: 0 !important;
  min-width: 320px;
}

.App > header {
  padding-right: 0 !important;
}


.login-page-header {
  font-size: 5.5rem;
  margin: 10px 50px 10px 50px;
  text-shadow: 1px 1px #343a40;
  font-weight: bold;
  letter-spacing: 3px;
}

.login-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}


.textfield__color {
  color: white !important;
}

@media (hover: hover) {
  .btn-selected:hover {
    background-color: #A80000 !important;
  }
}

@media (hover: hover) {
  .btn-unselected:hover {
    background-color: #A80000 !important;
  }
}

@media (hover: none) {
  .btn-selected:hover {
    background-color: #A80000 !important;
  }
}

@media (hover: none) {
  .btn-unselected:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.phone-input {
  width: 100%;
  color: white;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
}

.phone-input > input {
  background-color: transparent;
  width: 100%;
  color: white;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 13px;
  border: none;
}

.phone-input > input:focus-visible {
  border: none;
  outline: none;
}

.phone-input > div > select:focus {
  font-family: 'Montserrat';
  color: white;
  background-color: #191919;
}

.payment-input:focus-visible {
  outline: none;
}

:root {
  --PhoneInput-color--focus: none !important;
}

.payment-error {
  font-weight: 600;
  color: #d32f2f;
  font-family: 'Montserrat';
  font-size: 15px;
  width: 100%;
  text-align: center;
}

.video-responsive {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.payment-input {
  height: 50px;
}

@media (max-width: 600px) {
  .payment-input {
    height: 44px;
  }
}


/* HIDES ARROWS FOR NUMBER INPUTS */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


/* SCROLLBAR STUFF */
::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: white !important;
}